import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import close from "../assets/close.png";
import tick from "../assets/tick.png";
import academy from "../assets/academy.png";
import gaucho from "../assets/gaucho.png";
import iconic from "../assets/iconic.png";
import landify from "../assets/landify.png";
import typeheist from "../assets/typeheist.png";
import rightArrow from '../assets/right_arrow.png'
import Marquee from "react-fast-marquee";
import cube1 from '../assets/cube1.png';

const Trusted = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      mirror: false
    });
  }, []);

  return (
    <div>
      <section className="container trusted mt-4" id="whyzky" data-aos="fade-left">
        <p>
          Backed by next-gen companies at the forefront of future innovation
        </p>
        <Marquee pauseOnHover className="marquee-container">
          <ul>
            <li>
              <img alt="" src={typeheist} />
            </li>
            <li>
              <img alt="" src={gaucho} />
            </li>
            <li>
              <img alt="" src={iconic} />
            </li>
            <li>
              <img alt="" src={academy} />
            </li>
            <li>
              <img alt="" src={landify} />
            </li>
          </ul>
        </Marquee>
      </section>
      <section className="why-br container" id="whyzky" data-aos="zoom-in-up">
        <div>
          <span>Why ZKY ?</span>
          <p className="ans">
            We provide fast, accurate background checks with real-time access to
            extensive global databases, delivering customizable solutions and
            robust security for reliable verification.
          </p>
          <div className="cube1"><img src={cube1} className="cube-img" /></div>
          <p className="hero-subtitle">
            Bid farewell to outdated manual screening methods and embrace the
            future with 100% online automated checks. With Zky's best-in-class,
            hassle-free solutions, you can consistently deliver a seamless and
            exceptional experience for candidates, ensuring they feel valued and
            engaged throughout the process
          </p>
          <button
            className="btn-light mt-2"
            onClick={() => {
              window.open("https://sandbox.zky.co.in/request-demo", "_blank");
            }}
          >
            Get started for free
            <img src={rightArrow} alt="" className="right-arrow" />
          </button>
        </div>
      </section>
      <section className="ways container">
        <div className="cards">
          <div className="card-container ow" data-aos="fade-right">
            <h3>The Old Way</h3>
            <ul className="list-unstyled">
              <li>
                <div className="icon">
                  <img alt="" src={close} width={16} />
                </div>
                <div>
                  <label>Time-Consuming and Manual. </label>
                  <span>
                    Requires days—or even weeks—of emails and cold calls to
                    employers to gather essential documentation.
                  </span>
                </div>
              </li>
              <li>
                <div className="icon">
                  <img alt="" src={close} width={16} />
                </div>
                <div>
                  <label>Limited Data Access.</label>
                  <span>
                    Provides only a narrow range of information, restricting
                    comprehensive evaluations.
                  </span>
                </div>
              </li>
              <li>
                <div className="icon">
                  <img alt="" src={close} width={16} />
                </div>
                <div>
                  <label>Labor-Intensive Process. </label>
                  <span>
                    Relies on individuals for the manual collection and
                    validation of information.
                  </span>
                </div>
              </li>
              <li>
                <div className="icon">
                  <img alt="" src={close} width={16} />
                </div>
                <div>
                  <label>High Costs. </label>
                  <span>
                    Involves considerable manual effort and time for
                    consolidation and validation, resulting in elevated
                    expenses.
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div className="card-container card-container2 wz" data-aos="fade-left">
            <h3>With Zky</h3>
            <ul className="list-unstyled">
              <li>
                <div className="icon">
                  <img alt="" src={tick} width={16} />
                </div>
                <div>
                  <label>Swift and Snazzy. </label>
                  <span>
                    Say goodbye to verification hassles and hello to instant
                    data access, all with a smooth touch!
                  </span>
                </div>
              </li>
              <li>
                <div className="icon">
                  <img alt="" src={tick} width={16} />
                </div>
                <div>
                  <label>Data Vault.</label>
                  <span>
                    {" "}
                    Explore a rich landscape of information, featuring salary,
                    ITR, credit reports, and over 100 additional datasets!
                  </span>
                </div>
              </li>
              <li>
                <div className="icon">
                  <img alt="" src={tick} width={16} />
                </div>
                <div>
                  <label>Digital Delight.</label>
                  <span>
                    Experience a seamless journey as we gather and validate
                    information from various bureaus with just a flick of an
                    API.
                  </span>
                </div>
              </li>
              <li>
                <div className="icon">
                  <img alt="" src={tick} width={16} />
                </div>
                <div>
                  <label>Pocket-Friendly.</label>
                  <span>
                    Our automated workflows leave manual tasks behind, ensuring
                    low costs and high efficiency!
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Trusted;
