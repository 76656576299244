import React, { useState } from "react";
// import "./FAQSection.css";
import down from "../assets/down.png";

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(false);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "Does Zky offer any free trial?",
      answer:
        "Yes, Zky offers a free trial for 14 days to explore the features.",
    },
    {
      question: "Can I pick and choose data sources?",
      answer: "Yes, you can customize the data sources based on your needs.",
    },
    {
      question: "How do you charge for the service?",
      answer:
        "We offer various pricing plans based on usage and data sources selected.",
    },
    {
      question: "I am looking to become a partner",
      answer:
        "Please contact us at partner@zky.com to discuss partnership opportunities.",
    },
  ];

  return (
    <div className="faq-container">
      <div className="head-sec">
        <span>Questions & Answers</span>
        <h4>Got Questions? We’re All Ears!</h4>
        <p>
          If your answer isn’t here, just send us a message, and we’ll spring
          into action like a jack-in-the-box!
        </p>
        <button className="btn-light mt-2">Contact Us</button>
      </div>
      <div className="faq-right">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${openIndex === index ? "open" : ""}`}
            onMouseEnter={() => toggleAccordion(index)}
            onMouseLeave={() => toggleAccordion(index)}
          >
            <div className="faq-question">
              {faq.question}
              <span className={openIndex === index ? "rotate" : ""}>
                <img src={down} width={15} alt="" />
              </span>
            </div>
            {openIndex === index && (
              <div className="faq-answer">{faq.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
