import LandingPage from "./components/LandingPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Trusted from "./components/Trusted";
import Product from "./components/Product";
import Footer from "./components/Footer";
import { useEffect, useRef } from "react";

const Home = () => {
  return (
    <main className="mobile-container">
      <div className="landing-sec new-background-home">
        <LandingPage />
        <Trusted />
      </div>
      <Product />
      <Footer />
    </main>
  );
};

function App() {
  const moveTimeout = useRef();

  const handleMouseMove = (event) => {
    // Clear any previous timeout and set a new one
    if (!document.getElementById("root").classList?.contains("moving")) {
      document.getElementById("root").classList.add("moving");
    }
    if (moveTimeout.current) clearTimeout(moveTimeout.current);
    moveTimeout.current = setTimeout(() => {
      document.getElementById("root").classList.remove("moving");
    }, 300); // Adjust the delay
  };
  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/contact-us" element={<ContactUs />} /> */}
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
