import React, { useState } from "react";
import logo from "../assets/logo.png";
import loc from "../assets/loc.png";
import linkedin from "../assets/linkedin.png";
import twitter from "../assets/twitter.png";
import cube1 from '../assets/cube1.png';
import downArrow from '../assets/footer-down-arrow.png';
import downArrow2 from '../assets/footer-down-arrow2.png';

const mobileFooterNames = [
  {
    title: "Resources",
    subItems: ["Help center",
      "Help docs",
      "Developer docs"]
  },
  {
    title: "Features",
    subItems: ["Affiliates",
      "PayPal Subscriptions",
      "Usage-based Billing"]
  },
  {
    title: "Platform",
    subItems: ["Affiliates",
      "PayPal Subscriptions",
      "Usage-based Billing"]
  }
];

const Footer = () => {

  const [showSubFooter, setShowSubFooter] = useState({});

  const showFooterList = (index) => {
    setShowSubFooter({ [index]: !showSubFooter[index] });
  };

  return (
    <footer className="footer">
      <div className="mobile-footer">
        {mobileFooterNames.map((eachItem, index) => (
          <ul key={eachItem.title} className="footer-list">
            <div>
              <li
                onClick={() => showFooterList(index)}
                className="flex-between"
              >
                <p className="title">{eachItem.title}</p>
                {showSubFooter[index] ? (
                  <img src={downArrow2} alt="" className="down-arrow-footer" />
                ) : (
                  <img
                    src={downArrow}
                    alt=""
                    className="down-arrow-footer"
                  />
                )}
              </li>
              <hr className="white-horizontal" />
            </div>
            <ul>
              {showSubFooter[index] &&
                eachItem.subItems.map((eachSubItem) => (
                  <li key={eachItem}>
                    <p className="sub-title">
                      {eachSubItem}
                    </p>
                  </li>
                ))}
            </ul>
          </ul>
        ))}
      </div>
      <div className="footer-adress-container">
        <div className="footer-logo">
          <img src={logo} alt="ZKY Logo" />
        </div>
        <div className="footer-social">
          <h4>Follow us</h4>
          <div className="social-icons">
            <a
              href="https://x.com"
              aria-label="X"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="" src={twitter} width={25} />
            </a>
            <a
              href="https://linkedin.com"
              aria-label="LinkedIn"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="" src={linkedin} width={25} />
            </a>
          </div>
        </div>
        <div className="footer-address">
          <h4 className="in-flex-center">
            <img alt="" src={loc} width={15} />
            Address
          </h4>
          <p>
            28, Bajanai Madam Street,, Manjini Nagar,Muthyialpet, Pondicherry,
            Puducherry, India 605003
          </p>
        </div>
      </div>
      <div className="product-ready1 footer-space"><img src={cube1} className="cube-img" alt="" /></div>

      <div className="footer-links">
        <div className="footer-section">
          <h4>Resources</h4>
          <ul>
            <li>Help center</li>
            <li>Help docs</li>
            <li>Developer docs</li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Features</h4>
          <ul>
            <li>Affiliates</li>
            <li>PayPal Subscriptions</li>
            <li>Usage-based Billing</li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Platform</h4>
          <ul>
            <li>Affiliates</li>
            <li>PayPal Subscriptions</li>
            <li>Usage-based Billing</li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;